import React, { Component } from "react"
import { navigate } from 'gatsby-link'
import "./CSS/contact.css"
import SEO from "../components/seo"

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class ContactPage extends Component {

    constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
    }


    handleSubmit = e => {
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "MelinaContact", ...this.state })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));

    e.preventDefault();
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
    const { name, email, message } = this.state;
    return (
      <>
        <SEO title="Anarchy in the Kitchen - Me contacter" description="Anarchy in the Kitchen - Me contacter - Page de contact"/>
          <div className="maincontent center contactcontent">
          <div className="RogerRabbit">
            <div className='rabbit'>
              <div className="envelope"></div>
            </div>
            <div className='clouds'></div>
          </div>
          <h2 data-text-shadow='Me Contacter' data-aos="fade-down">Me Contacter</h2>
          <p data-aos="fade-right">Pour me contacter, remplissez le formulaire ci-joint:</p>
          
            <div id="contact-form" data-aos="fade-up">
              <form name="AnarchyContact" method="post" action="/merci/" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit} >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Ne pas remplir cela: <input name="bot-field"  />
                  </label>
                </p>

                <div>
                    <label htmlFor="name" data-aos="fade">
                      <span className="required">Nom: *</span> 
                      <input type="text" id="name" name="name" value={name} onChange={this.handleChange} placeholder="Votre nom" required="required" />
                    </label> 
                </div>
                <div>
                    <label htmlFor="email" data-aos="fade">
                      <span className="required">Email: *</span>
                      <input type="email" id="email" name="email" value={email} onChange={this.handleChange} placeholder="Votre email" required="required" />
                    </label>  
                </div>
                <div>		          
                    <label htmlFor="message" data-aos="fade">
                      <span className="required">Message: *</span> 
                      <textarea id="message" name="message" value={message} onChange={this.handleChange}  placeholder="Ecrivez votre message ici." required="required"></textarea> 
                    </label>  
                </div>
                <div>		           
                    <button name="submit" type="submit" id="submit" >Envoyer</button> 
                </div>

                
              </form>
            </div>
          </div>
      </>
    )
  }
}

export default ContactPage
